.quick-info {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%; }
  .quick-info .item-appear {
    transform: translate(0, 6rem);
    opacity: 0; }
    .quick-info .item-appear-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .quick-info .item-enter {
    transform: translate(0, 6rem);
    opacity: 0; }
    .quick-info .item-enter-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .quick-info .item-y-invert-enter {
    transform: translate(0, -6rem);
    opacity: 0; }
    .quick-info .item-y-invert-enter-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .quick-info .item-exit {
    transform: translate(0, 0);
    opacity: 1; }
    .quick-info .item-exit-active {
      transform: translate(0, 6rem);
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
    .quick-info .item-exit-done {
      transform: translate(0, 6rem);
      opacity: 0; }
  .quick-info .item-y-invert-exit {
    transform: translate(0, 0);
    opacity: 1; }
    .quick-info .item-y-invert-exit-active {
      transform: translate(0, 6rem);
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
    .quick-info .item-y-invert-exit-done {
      transform: translate(0, 6rem);
      opacity: 0; }
  .quick-info .icon {
    width: 60px;
    margin: 0 auto 2rem auto;
    display: block; }
  .quick-info .divisor {
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background: #d1d1d1; }
    @media (min-width: 576px) {
      .quick-info .divisor {
        width: 1px;
        height: auto;
        margin: 3rem -1px 3rem 0; } }
