.teachers .card {
  height: 22.5rem;
  color: white;
  cursor: zoom-in;
  overflow: hidden; }
  @media (max-width: 575.98px) {
    .teachers .card {
      height: 100vw; } }
  .teachers .card .card-body {
    background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
    background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
    background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
    opacity: 0;
    height: 100%;
    transition: all ease-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .teachers .card .card-body:hover {
      opacity: 1; }
  .teachers .card:hover {
    background-size: 105% 105%; }
  .teachers .card .card-text {
    text-align: center; }
