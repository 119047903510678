.hot-image {
  overflow: hidden;
  width: 100%;
  height: 100%; }
  .hot-image > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    transform: scale(1.001);
    transition: transform cubic-bezier(0, 0, 0.07, 1) 0.5s, opacity cubic-bezier(0, 0, 0.07, 1) 0.5s; }
    .hot-image > div:hover {
      transform: scale(1.05); }
      .hot-image > div:hover.transparent-on-hover {
        opacity: 0.8; }
