.galleria .photo {
  cursor: zoom-in; }
  .galleria .photo-appear {
    transform: translate(0, 20%);
    opacity: 0; }
    .galleria .photo-appear-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .galleria .photo-enter {
    transform: translate(0, 20%);
    opacity: 0; }
    .galleria .photo-enter-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .galleria .photo-y-invert-enter {
    transform: translate(0, -20%);
    opacity: 0; }
    .galleria .photo-y-invert-enter-active {
      transform: translate(0, 0);
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
  .galleria .photo-exit {
    transform: translate(0, 0);
    opacity: 1; }
    .galleria .photo-exit-active {
      transform: translate(0, 20%);
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
    .galleria .photo-exit-done {
      transform: translate(0, 20%);
      opacity: 0; }
  .galleria .photo-y-invert-exit {
    transform: translate(0, 0);
    opacity: 1; }
    .galleria .photo-y-invert-exit-active {
      transform: translate(0, 20%);
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: transform, opacity; }
    .galleria .photo-y-invert-exit-done {
      transform: translate(0, 20%);
      opacity: 0; }
