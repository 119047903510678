.pricebook {
  text-align: center; }
  .pricebook .item {
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 140%;
    padding: 0.5rem 0; }
    .pricebook .item-appear {
      transform: translate(-30%, 0);
      opacity: 0; }
      .pricebook .item-appear-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .pricebook .item-enter {
      transform: translate(-30%, 0);
      opacity: 0; }
      .pricebook .item-enter-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .pricebook .item-exit {
      transform: translate(0, 0);
      opacity: 1; }
      .pricebook .item-exit-active {
        transform: translate(-30%, 0);
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
      .pricebook .item-exit-done {
        transform: translate(-30%, 0);
        opacity: 0; }
    .pricebook .item .heading {
      padding-right: 3rem;
      margin-bottom: 0.5rem; }
    .pricebook .item .price {
      margin-bottom: 0.5rem; }
    .pricebook .item .uptitle {
      letter-spacing: 2.5px;
      font-size: 60%;
      margin: 0.5rem 0; }
