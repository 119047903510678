.schedule table.table-view td:first-of-type, .schedule .lesson .name {
  text-transform: uppercase;
  font-weight: 400; }

.schedule .times-separator {
  height: 1px;
  width: 30%;
  margin: 0 auto;
  background-color: #bababa; }

.schedule table.table-view td:first-of-type, .schedule .lesson .name {
  font-size: 1.1rem;
  text-align: left; }

.schedule .lesson .entry .day .week-day-abbr, .schedule table.table-view th:not(:first-of-type), .schedule .lesson .entry .day:nth-of-type(6) .week-day-abbr, .schedule .lesson .entry .day:nth-of-type(7) .week-day-abbr, .schedule table.table-view th:nth-of-type(7), .schedule table.table-view th:nth-of-type(8) {
  font-weight: 300;
  padding: 0.25rem;
  background-color: inherit;
  color: inherit;
  text-align: center;
  vertical-align: middle; }

.schedule .lesson .entry .day .times, .schedule table.table-view td:not(:first-of-type), .schedule .lesson .entry .day:nth-of-type(6) .times, .schedule .lesson .entry .day:nth-of-type(7) .times, .schedule table.table-view td:nth-of-type(7), .schedule table.table-view td:nth-of-type(8) {
  text-align: center;
  vertical-align: middle; }

.schedule .lesson .entry .day .times:hover, .schedule table.table-view td:hover:not(:first-of-type), .schedule table.table-view tr:hover td:not(:first-of-type) {
  background-color: #767b81;
  color: white; }

.schedule .lesson .entry .day .times, .schedule table.table-view td:not(:first-of-type) {
  background-color: #5a6168;
  color: #f8f9fa; }

.schedule .lesson .entry .day .week-day-abbr, .schedule table.table-view th:not(:first-of-type) {
  background-color: inherit;
  color: inherit; }

.schedule .lesson .entry .day:nth-of-type(6) .times:hover, .schedule .lesson .entry .day:nth-of-type(7) .times:hover, .schedule table.table-view td:hover:nth-of-type(7), .schedule table.table-view td:hover:nth-of-type(8), .schedule table.table-view tr:hover td:nth-of-type(7), .schedule table.table-view tr:hover td:nth-of-type(8) {
  background-color: #767a80;
  color: #ebb1b1; }

.schedule .lesson .entry .day:nth-of-type(6) .times, .schedule .lesson .entry .day:nth-of-type(7) .times, .schedule table.table-view td:nth-of-type(7), .schedule table.table-view td:nth-of-type(8) {
  background-color: #5a6168;
  color: #e69797; }

.schedule .lesson .entry .day:nth-of-type(6) .week-day-abbr, .schedule .lesson .entry .day:nth-of-type(7) .week-day-abbr, .schedule table.table-view th:nth-of-type(7), .schedule table.table-view th:nth-of-type(8) {
  background-color: inherit;
  color: #e41c1c; }

.schedule table.table-view td:hover:first-of-type, .schedule table.table-view tr:hover td:first-of-type {
  background-color: #454a50;
  color: white; }

.schedule table.table-view td:first-of-type {
  background-color: #343a40;
  color: #f8f9fa; }

.schedule table.table-view th:first-of-type {
  background-color: inherit;
  color: inherit; }

.schedule .lesson {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 0.5rem 0; }
  .schedule .lesson .entry {
    width: 11rem; }
    .schedule .lesson .entry-appear {
      transform: translate(-30%, 0);
      opacity: 0; }
      .schedule .lesson .entry-appear-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .schedule .lesson .entry-enter {
      transform: translate(-30%, 0);
      opacity: 0; }
      .schedule .lesson .entry-enter-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .schedule .lesson .entry-exit {
      transform: translate(0, 0);
      opacity: 1; }
      .schedule .lesson .entry-exit-active {
        transform: translate(-30%, 0);
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
      .schedule .lesson .entry-exit-done {
        transform: translate(-30%, 0);
        opacity: 0; }
    .schedule .lesson .entry .day {
      margin-bottom: 4px; }
      .schedule .lesson .entry .day .week-day-abbr {
        font-size: 1.1rem !important; }
      .schedule .lesson .entry .day .times {
        display: flex;
        justify-content: center;
        align-items: center; }

.schedule table.table-view {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px; }
  .schedule table.table-view th,
  .schedule table.table-view td {
    transition-property: background-color color;
    transition-duration: 0.1s;
    transition-timing-function: linear; }
  .schedule table.table-view td {
    padding: 0.75rem; }
