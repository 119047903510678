.logo {
  width: 17.78rem;
  max-width: 17.78rem; }
  .logo > div {
    background-image: url(../images/caption.webp);
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-size: contain;
    background-position: center;
    padding-bottom: 3.0226rem; }

.navbar {
  padding: 1rem;
  margin: 0 auto;
  box-sizing: content-box; }
  @media (min-width: 870px) {
    .navbar .navbar-toggler {
      display: none; } }
  .navbar .navbar-collapse {
    position: absolute;
    align-items: center;
    flex-basis: auto; }
    .navbar .navbar-collapse .navbar-nav {
      flex-direction: column;
      align-items: center; }
      .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        padding-left: 0.75em;
        padding-right: 0.75em;
        padding-bottom: 0;
        display: flex;
        align-items: center; }
    .navbar .navbar-collapse.left-panel {
      left: 0;
      position: static; }
      @media (min-width: 870px) {
        .navbar .navbar-collapse.left-panel {
          position: absolute;
          display: flex;
          height: auto;
          transition: none;
          overflow: inherit; }
          .navbar .navbar-collapse.left-panel .navbar-nav {
            max-width: calc((100vw - 350px) / 2);
            flex-wrap: wrap; }
            .navbar .navbar-collapse.left-panel .navbar-nav .nav-item .nav-link {
              padding-bottom: 0.15rem;
              padding-top: 0.15rem; } }
      @media (min-width: 292px) {
        .navbar .navbar-collapse.left-panel .navbar-nav {
          flex-direction: row; } }
    .navbar .navbar-collapse.right-panel {
      right: 0;
      position: static; }
      @media (min-width: 700px) {
        .navbar .navbar-collapse.right-panel {
          position: absolute;
          display: flex;
          height: auto;
          transition: none;
          overflow: inherit; }
          .navbar .navbar-collapse.right-panel .navbar-nav {
            max-width: calc((100vw - 350px) / 2);
            flex-wrap: wrap; }
            .navbar .navbar-collapse.right-panel .navbar-nav .nav-item .nav-link {
              padding-bottom: 0.15rem;
              padding-top: 0.15rem; } }
      @media (min-width: 207px) {
        .navbar .navbar-collapse.right-panel .navbar-nav {
          flex-direction: row; } }
    .navbar .navbar-collapse.right-panel .icon {
      height: 1.3rem; }
    .navbar .navbar-collapse.right-panel .phone {
      display: inline-flex; }
      .navbar .navbar-collapse.right-panel .phone label {
        font-weight: 300;
        margin: 0 0 0 0.625rem;
        font-size: 1.2rem;
        display: none; }
        @media (min-width: 1150px) {
          .navbar .navbar-collapse.right-panel .phone label {
            display: initial; } }
