.cover {
  width: 100%;
  height: 661px;
  max-height: calc(100vh - 87px);
  background-image: url(../images/cover.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: none; }
  .cover-appear {
    opacity: 0; }
    .cover-appear-active {
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: opacity; }
  .cover-enter {
    opacity: 0; }
    .cover-enter-active {
      opacity: 1;
      transition-delay: 0s;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: opacity; }
  .cover-exit {
    opacity: 1; }
    .cover-exit-active {
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
      transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
      transition-property: opacity; }
    .cover-exit-done {
      opacity: 0; }
  @media (min-height: 400px) and (min-width: 350px) {
    .cover {
      display: inherit; } }
  .cover [class^="cover-"] {
    margin: 0; }
  .cover .cover-gradient {
    background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
    padding: 0 50px; }
  .cover .cover-title {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.17;
    padding: 24px 0 12px 0; }
    @media (min-width: 576px) and (min-height: 450px) {
      .cover .cover-title {
        font-size: 3.216rem; } }
    @media (min-width: 800px) and (min-height: 600px) {
      .cover .cover-title {
        font-size: 4.30944rem; } }
    .cover .cover-title-appear {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-title-appear-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-title-enter {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-title-enter-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-title-exit {
      transform: translate(0, 0);
      opacity: 1; }
      .cover .cover-title-exit-active {
        transform: translate(0, 50%);
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
      .cover .cover-title-exit-done {
        transform: translate(0, 50%);
        opacity: 0; }
  .cover .cover-description {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    padding: 0 0 16px 0; }
    @media (min-width: 576px) and (min-height: 450px) {
      .cover .cover-description {
        font-size: 1.34rem; } }
    @media (min-width: 800px) and (min-height: 600px) {
      .cover .cover-description {
        font-size: 1.7956rem; } }
    .cover .cover-description-appear {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-description-appear-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.8s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-description-enter {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-description-enter-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.8s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-description-exit {
      transform: translate(0, 0);
      opacity: 1; }
      .cover .cover-description-exit-active {
        transform: translate(0, 50%);
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
      .cover .cover-description-exit-done {
        transform: translate(0, 50%);
        opacity: 0; }
  .cover .cover-arrow {
    display: block;
    bottom: 0px;
    padding: 24px;
    color: #bababa; }
    .cover .cover-arrow-appear {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-arrow-appear-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 1s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-arrow-enter {
      transform: translate(0, 50%);
      opacity: 0; }
      .cover .cover-arrow-enter-active {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 1s;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
    .cover .cover-arrow-exit {
      transform: translate(0, 0);
      opacity: 1; }
      .cover .cover-arrow-exit-active {
        transform: translate(0, 50%);
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: cubic-bezier(0, 0.5, 0.25, 1);
        transition-property: transform, opacity; }
      .cover .cover-arrow-exit-done {
        transform: translate(0, 50%);
        opacity: 0; }
    .cover .cover-arrow:hover {
      opacity: 0.7; }
