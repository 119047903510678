.certificate {
  position: relative;
  text-align: center;
  max-height: 137.41mm;
  height: 60vw; }
  .certificate .background {
    width: 100%;
    height: 100%; }
  .certificate .front {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
