.contacts {
  background-color: #635b66;
  color: white; }
  .contacts a {
    color: white; }
  .contacts .icon {
    height: 2rem;
    margin: 0.5rem; }
  .contacts .map {
    width: 100%;
    height: 50vh;
    overflow: hidden; }
    @media (min-width: 992px) {
      .contacts .map {
        width: 50%;
        height: 550px; } }
