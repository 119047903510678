.icon .icon-primary-path {
  opacity: 1; }

.icon .icon-secondary-path {
  opacity: 0.4; }

.icon .stroke0 {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10; }
